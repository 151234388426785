import React from 'react'

function Profile(props) {
  const t = props.teamMember
  return (
    <div className="profile">
      <img src={props.img.default} alt={t.name} />
      <h4>{t.name}</h4>
      <h5>{t.title}</h5>
      <p>{t.blurb}</p>
    </div>
  )
}

export default Profile

