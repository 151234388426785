import React, { Component } from 'react'
import ServicePreview from '../components/ServicePreview';
import { services } from '../constants'

export default class Services extends Component {
    render() {
        return (
            <div className="services container" id="services">
                <h3>Personalized Cleaning Services for the Greater Houston Area</h3>
                <div className="service-collection">
                    {services.map(service => {
                        let img = require(`../assets/${service.img}.png`);
                        return <ServicePreview key={service.title} service={service} img={img} />
                    })}
                </div>
                <div className="vertical-rule" />
                <p>We also offer 24-hour emergency damage restoration, so <a href="tel:832-422-7455">give us a call</a>!</p>
            </div>
        )
    }
}
