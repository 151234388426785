import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';

function ServicePreview(props) {
  const service = props.service
  return (
    // <Link to={`/services#${service.id}`} className="service-preview">
    <div className="service-preview">
      <img src={props.img.default} alt={service.name} />
      <h4>{service.title}</h4>
      <p>{service.blurb}</p>
    </div>
    // </Link>
  )
}

export default ServicePreview