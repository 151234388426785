import React, { Component } from 'react'
import Hero from '../containers/Hero'
import Team from '../containers/Team'
import Services from '../containers/Services'
import Testimonials from '../containers/Testimonials'
import Contact from '../containers/Contact'


export default class HomePage extends Component {
    render() {
        return (
            <div>
                <Hero />
                <Team />
                <Services />
                <Testimonials />
                <Contact />
            </div>
        )
    }
}
