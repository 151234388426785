import React, { Component } from 'react'
import { Formik, Form, Field } from 'formik';

export default class ContactForm extends Component {
  validateForm = (values, field) => {
    if (values[field] === '' || values[field] === null) {
      return '* Please make sure all fields are complete';
    }
  }

  onSubmit = (values, actions) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": "contact-form",
        ...values
      })
    })
      .then(() => {
        alert("Thank you! We'll be in touch soon.");
        actions.resetForm()
      })
      .catch(() => {
        alert('Error');
      })
      .finally(() => actions.setSubmitting(false))
  }

  encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  render() {
    return (
      <div className="contact-form">
        <h3>Get in Touch With Us</h3>
        <Formik
          initialValues={{
            // "bot-field": null,
            "form-name": "contact-form",
            name: '',
            email: '',
            phone: '',
            address: '',
            comment: '',
          }}
          onSubmit={(values, actions) => this.onSubmit(values, actions)}
        >
          {({ values, submitCount, isValid, errors }) => {
            return (
              <Form name="contact-form" data-netlify={true}>
                <input type="hidden" name="form-name" value="contact-form" />
                <input type="hidden" name="bot-field" value={null} />
                <Field
                  name="name"
                  validate={() => this.validateForm(values, 'name')}
                >{({ field, form: { isSubmitting } }) => (
                  <input className="form-field name" {...field} disabled={isSubmitting} name="name" type="text" placeholder="Name" />
                )}</Field>
                <Field
                  name="email"
                  type="email"
                  validate={() => this.validateForm(values, 'email')}
                >{({ field, form: { isSubmitting } }) => (
                  <input className="form-field email" {...field} disabled={isSubmitting} name="email" type="email" placeholder="Email" />
                )}</Field>
                <Field
                  name="phone"
                  type="tel"
                  validate={() => this.validateForm(values, 'phone')}
                >{({ field, form: { isSubmitting } }) => (
                  <input className="form-field phone" {...field} disabled={isSubmitting} name="phone" type="tel" placeholder="Phone" />
                )}</Field>
                <Field
                  name="address"
                  validate={() => this.validateForm(values, 'address')}
                >{({ field, form: { isSubmitting } }) => (
                  <input className="form-field address" {...field} disabled={isSubmitting} name="address" type="text" placeholder="Physical Address" />
                )}</Field>
                <Field
                  name="comment"
                  validate={() => this.validateForm(values, 'comment')}
                >{({ field, form: { isSubmitting } }) => (
                  <textarea className="form-field comment" {...field} disabled={isSubmitting} name="comment" type="text" placeholder="What have you got for us?" />
                )}</Field>
                <div className="submit">
                  {(submitCount > 0 && !isValid) && <p className="error">{errors.name}</p>}
                  <button type="submit">Let's Do This</button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div >
    )
  }
}
