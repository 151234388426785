import React, { Component } from 'react'
import guage from '../assets/gauge-white.svg'

export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <p>© TeamRevvedUp 2021. All Rights Reserved.</p>
                <hr />
                <img src={guage} alt='tru-logo-text' className='logo' />
            </div>
        )
    }
}
