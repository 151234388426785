import React, { Component } from 'react'
import ServiceFull from '../components/ServiceFull'
import { services } from '../constants'

export default class ServicesPage extends Component {
    render() {
        return (
            <div className="services-page services">
                <h2>Services</h2>
                {services.map(service => {
                    let img = require(`../assets/${service.img}.png`);
                    return <ServiceFull key={service.id} service={service} img={img} />
                })}
            </div>
        )
    }
}
