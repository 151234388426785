import React from 'react'

function ServiceFull(props) {
  const service = props.service
  return (
    <div className="service-full container" id={service.id} style={{ backgroundColor: service.id % 2 === 0 ? '#eee' : '#d8d8d8' }} >
      <h4>{service.title}</h4>
      <p>{service.blurb}</p>
      <img src={props.img.default} alt={service.name} />
    </div>
  )
}

export default ServiceFull