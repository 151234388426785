import React, { Component } from 'react'
import { testimonials } from '../constants'
import star from '../assets/star.png'
import caret from '../assets/caret.png'

export default class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTestimonial: 0,
            intervalId: null
        };
    }

    componentDidMount = () => {
        let intervalId = setInterval(() => {
            this.changeTestimonial('next')
        }, 15000);

        this.setState({
            intervalId: intervalId
        })
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    updateTestimonial = (direction) => {
        clearInterval(this.state.intervalId);
        this.changeTestimonial(direction)
        let intervalId = setInterval(() => {
            this.changeTestimonial(direction)
        }, 10000);

        this.setState({
            intervalId: intervalId
        })
    }

    changeTestimonial = (direction) => {
        let updatedTestimonial = this.state.selectedTestimonial
        if (direction === 'next') {
            if (this.state.selectedTestimonial === testimonials.length - 1) {
                updatedTestimonial = 0
            } else {
                updatedTestimonial += 1
            }
        } else {
            if (this.state.selectedTestimonial === 0) {
                updatedTestimonial = testimonials.length - 1
            } else {
                updatedTestimonial -= 1
            }
        }

        this.setState({
            selectedTestimonial: updatedTestimonial
        })
    }

    render() {
        return (
            <div className="testimonials container" id="testimonials" >
                <button onClick={() => this.updateTestimonial('previous')}><img alt="left caret" className="left-caret caret" src={caret} /></button>
                <a className="testimonial" alt="google reviews" target="_blank" href="https://g.page/TRUCarpet?share">
                    <div className="rating">{[...Array(testimonials[this.state.selectedTestimonial].rating)].map((n, i) => <img className="star" alt='star' src={star} key={i} />)}</div>
                    <p>{testimonials[this.state.selectedTestimonial].name}</p>
                    <p>{testimonials[this.state.selectedTestimonial].comment}</p>
                </a>
                <button onClick={() => this.updateTestimonial('next')}><img alt="right caret" className="right-caret caret" src={caret} /></button>
            </div>
        )
    }
}
