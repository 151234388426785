import React, { Component } from 'react'
import Profile from '../components/Profile'
import { team } from '../constants'
import gauge from '../assets/gauge-black.svg'

export default class Team extends Component {
    render() {
        return (
            <div className="team container" id="team" >
                <h3>We’re the Hardest Working Owners You’ll Ever Meet</h3>
                <div className="profiles">
                    {team.map(teamMember => {
                        let img = require(`../assets/${teamMember.img}.png`);
                        return <Profile key={teamMember.name} teamMember={teamMember} img={img} />
                    })}
                </div>
                <div className="vertical-rule" />
                <div className="motto">
                    <img src={gauge} alt="TRU" />
                    <p>TeamRevvedUP (TRU). TRU to the words that we speak, TRU to the work that we perform, TRU to the people who stand with us.</p>
                </div>
            </div>
        )
    }
}
