import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Header from './containers/Header';
import Footer from './containers/Footer';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';

export default function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <Switch>
          <Route path='/services'>
            <ServicesPage />
          </Route>
          <Route path='/'>
            <HomePage />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}
