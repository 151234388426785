import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../assets/logo-guy.svg'
import Button from '../components/Button';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLinkText: 'What We Do',
      firstLinkLocation: '/services',
      isHamburgerOpen: false,
      windowWidth: 0
    };
  }

  componentDidMount() {
    this.updateFirstLink()
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions);

    if (this.state.isHamburgerOpen) {
      document.body.style.overflow = 'hidden';
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
    this.setState({
      isHamburgerOpen: false,
    })
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
    if (window.innerWidth > 1000) {
      this.setState({
        isHamburgerOpen: false
      })
      document.body.style.overflow = 'unset';
    }
  };

  updateFirstLink = () => {
    this.setState({
      firstLinkText: window.location.pathname === '/services' ? 'Home' : 'What We Do',
      firstLinkLocation: window.location.pathname === '/services' ? '/' : '/services'
    })
  }

  toggleHamburger = () => {
    const { isHamburgerOpen, windowWidth } = this.state

    if (windowWidth < 1000) {
      document.body.style.overflow = isHamburgerOpen ? 'unset' : 'hidden'

      this.setState({
        isHamburgerOpen: !this.state.isHamburgerOpen
      })
    }


    this.updateFirstLink()
  }

  onLinkClickHandler = () => {
    this.toggleHamburger()
    this.updateFirstLink()
  }

  render() {
    const { isHamburgerOpen, windowWidth } = this.state;
    return (
      <div className='header'>
        <div className="branding">
          <img src={logo} alt='tru-logo' className='logo' />
          <div className="company-name">
            <h1>TeamRevvedUp</h1>
            <h5>Carpet and Tile Cleaning</h5>
          </div>
        </div>
        <nav className={(isHamburgerOpen && windowWidth <= 1000) ? 'hamburger-nav' : 'horizontal-nav'}>
          <ul>
            {/* <li onClick={this.onLinkClickHandler}>
              <Link to={this.state.firstLinkLocation}>{this.state.firstLinkText}</Link>
            </li> */}
            <li onClick={this.onLinkClickHandler}>
              <Link smooth to='/#services'>What We Do</Link>
            </li>
            <li onClick={this.onLinkClickHandler}>
              <Link smooth to='/#team'>Who We Are</Link>
            </li>
            <li onClick={this.onLinkClickHandler}>
              <Link smooth to='/#testimonials'>Testimonials</Link>
            </li>
            <li onClick={this.onLinkClickHandler}>
              <Link smooth to='/#contact'><Button text="Get in Touch" /></Link>
            </li>
          </ul>
        </nav>
        <div className="hamburger-icon" onClick={this.onLinkClickHandler}>
          <span className={isHamburgerOpen ? 'hamburger-active' : ''}></span>
          <span className={isHamburgerOpen ? 'hamburger-active' : ''}></span>
          <span className={isHamburgerOpen ? 'hamburger-active' : ''}></span>
        </div>
      </div>
    );
  }
}