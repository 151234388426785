export const team = [
  {
    img: 'jason',
    name: 'Jason Jones',
    title: 'CEO/Service Technician',
    blurb:
      'After 23 years in the cleaning industry, there are not many things that Jason has not had a hand in cleaning. In the beginning of his career, he focused on commercial carpeting, and is a deft hand at hard surface and air duct cleaning. Having worked his way up to General Manager of a major carpet cleaner in Houston, he feels that it has been a long time coming that he finally started his own business, and ended the cycle of inefficiency that has plagued him at other companies.',
  },
  {
    img: 'chance',
    name: 'Chance Spence',
    title: 'CFO/Service Technician',
    blurb:
      'With 10+ years of experience cleaning carpet and hard surfaces, Chance brings a business management background to the team. He wants anyone who looks at this website to know that the images that you see here are our actual work, not some stock photos that were pulled from the internet. This team takes pride in everything that they do.',
  },
  {
    img: 'tyler',
    name: 'Tyler Lambert',
    title: 'COO/Service Technician',
    blurb:
      "Being trained by one of the industry’s best has made Tyler a well rounded technician at a young age. Tyler's can-do attitude and problem solving techniques have launched him to the very top of every division that he has entered.",
  },
]

export const services = [
  {
    id: 1,
    img: 'carpet-cleaning',
    title: 'Carpet Cleaning',
    blurb:
      'Most homes only need a little freshening up. We use the hot water/extraction method combined with our biodegradable all natural cleaning solutions to achieve jaw-dropping results that will last.',
  },
  {
    id: 2,
    img: 'grout-tile',
    title: 'Tile and Grout Cleaning',
    blurb:
      "Haven't seen the original color of your grout in a while? We’re here to help! Be sure to ask us how to keep your tile and grout in great shape once we get it looking like new again.",
  },
  {
    id: 3,
    img: 'carpet-repair',
    title: 'Carpet Repair',
    blurb:
      "Unsightly wrinkles, rips and tears? TRU has the repair! Not only does it make for an eyesore, but it can be a tripping hazard. Let et out there and come up with a plan to correct it today!",
  },
  {
    id: 4,
    img: 'duct-cleaning',
    title: 'Air Duct Cleaning',
    blurb:
      'Do you fill your vacuum cannister in every room that you vacuum? Too much dust on your hard surfaces only 2 days after cleaning? Do you or a family member suffer from allergies or asthma? You should have your air ducts cleaned every 3-5 years to keep the air that you breathe healthier.',
  },
  {
    id: 5,
    img: 'floor-resurfacing',
    title: 'Wood Floor Resurfacing',
    blurb:
      'Hardwoods a little worse for the wear? We have the tools and expertise to make them like new without removing the stain or color.',
  },
  {
    id: 6,
    img: 'floor-restoration',
    title: 'Wood Floor Restoration',
    blurb:
      'Deep gouges in your hardwood? Want a different feel or color without the cost of replacing? Hardwood floor restoration may be the ticket. If we can make a 120 year old floor look like it was installed last week, I know we can make yours stand out.',
  },
  {
    id: 7,
    img: 'pet-treatment',
    title: 'Pet Treatment',
    blurb:
      'We love our furry friends and they love our soft surfaces. TRU has the products and the know how to get them looking and smelling fresh once more.',
  },
  {
    id: 8,
    img: 'upholstery',
    title: 'Upholstery and Drapes',
    blurb:
      "If your favorite seat on the sofa doesn't look like the rest of the sofa any more or you spilled a little something on that armchair, we’ve got you!",
  },
  {
    id: 9,
    img: 'vertical-surfaces',
    title: 'Vertical Surfaces',
    blurb:
      'Is your kitchen backsplash coated in hard to clean grease? Shower walls not as shiny as the day you fell in love with that walk-in shower? We have the tools to clean vertical surfaces as well.',
  },
]

export const testimonials = [
  {
    rating: 5,
    name: 'Cathy Johnson',
    comment: 'Carpet looks amazing! The team was on time, professional, explained the process and friendly. I look forward to working with them again for other services offered.'
  },
  {
    rating: 5,
    name: 'Melissa W',
    comment: 'I highly recommend Jason and the crew at Team Revved Up! These guys were professional and courteous. My dog got into some human food and it tore his stomach up. Unfortunately, he got really sick in my bedroom. I had tried to treat it myself, but it was just too much for me to handle. I called Jason and he responded immediately! They came in, we discussed a plan and they put it into action. They pulled the carpet back, pulled out the pad, dried the plywood and left a fan so that it would all dry up. They also cleaned the other part of the carpet. Then they came back a few days later, treated the plywood, installed new padding and cleaned the carpet again. Wow! My bedroom carpet now looks AMAZING and smells wonderful. These guys do it right and price fairly with no up charge or hidden fees. I am very satisfied and will be using them again. Thanks Jason!!'
  },
  {
    rating: 5,
    name: 'Dawn Fuge',
    comment: 'These guys did a fantastic job. We had high traffic and pet stains and now our carpets look great. The team came in and from start to finish completed the job for 4 rooms in 1 hour. Very friendly. Highly recommend this team.'
  },
  {
    rating: 5,
    name: 'Brianne Morse',
    comment: 'Highly recommend Team Revved Up. We wanted all the carpets cleaned, padding in one room replaced (pet damage), and a broken tile repaired. Getting an estimate and scheduling an appointment was hassle free! These guys were professional and courteous. No hidden cost/fees. No upsales. Everything looks AMAZING. The room with the pet damage is perfect again. Heavy traffic areas match the rest of the carpet. Spills from the kids are gone. The carpet all over the is clean and fluffy! Will definitely use Team Revved Up when we get the tiles cleaned.'
  }
]