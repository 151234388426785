import React, { Component } from 'react';
import team from '../assets/team.png'
import Button from '../components/Button';
import { HashLink as Link } from 'react-router-hash-link';


export default class Hero extends Component {
  render() {
    return (
      <div className="hero container">
        <div className="hero-text">
          <h2>Personalized and Personable Residential and Commercial Cleaning in Houston</h2>
          <h5>We’re TeamRevvedUp and we’re ready to personalize your cleaning needs. No job is too big or small, so reach out to us today to request an in-home demonstration.</h5>
          <Link smooth to='/#contact'><Button text="Get in Touch" /></Link>
        </div>
        <img src={team} alt="team" />
      </div>
    );
  }
}
