import React, { Component } from 'react'
import ContactForm from '../components/ContactForm'

export default class Contact extends Component {
    render() {
        return (
            <div className="contact container" id="contact">
                <div className="form">
                    <ContactForm />
                </div>
                <hr />
                <div className="location">
                    <iframe
                        title="houston-map"
                        className="map"
                        src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d443328.13627659174!2d-95.34972878742575!3d29.763149746615582!3m2!1i1024!2i768!4f13.1!2m1!1shouston%20outer%20loop!5e0!3m2!1sen!2sus!4v1588475779475!5m2!1sen!2sus"
                        height="350"
                        frameBorder="0"
                        allowFullScreen=""
                    ></iframe>
                    <h3>Proudly Serving the Houston, TX Area</h3>
                    <p>Give us a call at <a href="tel:832-422-7455">832-422-7455</a> or fill out the form and we’ll get back to you promptly.</p>
                    <p>We’re excited to meet you!</p>
                </div>
            </div>
        )
    }
}
